<template>
	<div class="main">
		<base-card>
			<section>
				<h2>Register as a coach now!</h2>
			</section>
			<coach-form @save-data="saveData"></coach-form>
		</base-card>
	</div>
</template>

<script>
	import CoachForm from "../../components/coaches/CoachForm.vue";
	export default {
		components: {
			CoachForm,
		},
		methods: {
			async saveData(data) {
				try {
					await this.$store.dispatch("coaches/registerCoach", data);
					this.$router.replace("/coaches");
				} catch (e) {
					console.log(e.error.message ?? "Error sending request");
				}
			},
		},
	};
</script>
