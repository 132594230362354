<template>
	<form @submit.prevent="submitForm">
		<div class="form-control" :class="{ invalid: !firstName.isValid }">
			<label for="firstname">First Name</label>
			<input
				type="text"
				id="firstname"
				v-model="firstName.val"
				@blur="clearInvalid('firstName')"
			/>
			<p v-if="!firstName.isValid">First name must not be empty.</p>
		</div>
		<div class="form-control" :class="{ invalid: !lastName.isValid }">
			<label for="lastname">Last Name</label>
			<input
				type="text"
				id="lastname"
				v-model.trim="lastName.val"
				@blur="clearInvalid('lastName')"
			/>
			<p v-if="!lastName.isValid">Last name must not be empty.</p>
		</div>
		<div class="form-control" :class="{ invalid: !description.isValid }">
			<label for="description">description</label>
			<textarea
				id="description"
				rows="5"
				v-model.trim="description.val"
				@blur="clearInvalid('description')"
			></textarea>
			<p v-if="!description.isValid">Description must not be empty.</p>
		</div>
		<div class="form-control" :class="{ invalid: !rate.isValid }">
			<label for="rate">Hourly Rate</label>
			<input
				type="number"
				id="rate"
				v-model.number="rate.val"
				@blur="clearInvalid('rate')"
			/>
			<p v-if="!rate.isValid">Rate must be greater than $0.</p>
		</div>
		<div class="form-control" :class="{ invalid: !areas.isValid }">
			<h3>Areas of Expertise</h3>
			<div>
				<input
					type="checkbox"
					id="frontend"
					value="frontend"
					v-model="areas.val"
					@blur="clearInvalid('areas')"
				/>
				<label for="frontend">Frontend Development</label>
			</div>
			<div>
				<input
					type="checkbox"
					id="backend"
					value="backend"
					v-model="areas.val"
					@blur="clearInvalid('areas')"
				/>
				<label for="backend">Backend Development</label>
			</div>
			<div>
				<input
					type="checkbox"
					id="career"
					value="career"
					v-model="areas.val"
					@blur="clearInvalid('areas')"
				/>
				<label for="career">Career Advisory</label>
				<p v-if="!areas.isValid">
					You must select at least one area of expertise.
				</p>
			</div>
		</div>
		<div class="form-control">
			<p v-if="!formIsValid">
				Please fix the above errors and submit again.
			</p>
			<base-button>Register!</base-button>
		</div>
	</form>
</template>

<script>
	export default {
		emits: ["save-data"],
		data() {
			return {
				firstName: {
					val: "",
					isValid: true,
				},
				lastName: {
					val: "",
					isValid: true,
				},
				description: {
					val: "",
					isValid: true,
				},
				rate: {
					val: null,
					isValid: true,
				},
				areas: {
					val: [],
					isValid: true,
				},
				formIsValid: true,
			};
		},
		methods: {
			submitForm() {
				this.validateForm();

				if (!this.formIsValid) {
					return;
				}

				const formData = {
					first: this.firstName.val,
					last: this.lastName.val,
					desc: this.description.val,
					rate: this.rate.val,
					areas: this.areas.val,
				};
				this.$emit("save-data", formData);
			},
			validateForm() {
				this.formIsValid = true;

				if (this.firstName.val === "") {
					this.firstName.isValid = false;
					this.formIsValid = false;
				}
				if (this.lastName.val === "") {
					this.lastName.isValid = false;
					this.formIsValid = false;
				}
				if (this.description.val === "") {
					this.description.isValid = false;
					this.formIsValid = false;
				}
				if (!this.rate.val || this.rate.val < 0) {
					this.rate.isValid = false;
					this.formIsValid = false;
				}
				if (this.areas.val.length === 0) {
					this.areas.isValid = false;
					this.formIsValid = false;
				}
			},
			clearInvalid(input) {
				this[input].isValid = true;
			},
		},
	};
</script>

<style scoped>
	.form-control {
		margin: 0.5rem 0;
	}

	label {
		font-weight: bold;
		display: block;
		margin-bottom: 0.5rem;
	}

	input[type="checkbox"] + label {
		font-weight: normal;
		display: inline;
		margin: 0 0 0 0.5rem;
	}

	input,
	textarea {
		display: block;
		width: 100%;
		border: 1px solid #ccc;
		font: inherit;
	}

	input:focus,
	textarea:focus {
		background-color: #f0e6fd;
		outline: none;
		border-color: #3d008d;
	}

	input[type="checkbox"] {
		display: inline;
		width: auto;
		border: none;
	}

	input[type="checkbox"]:focus {
		outline: #3d008d solid 1px;
	}

	h3 {
		margin: 0.5rem 0;
		font-size: 1rem;
	}

	.invalid label {
		color: red;
	}

	.invalid input,
	.invalid textarea {
		border: 1px solid red;
	}
</style>
